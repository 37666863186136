import React, { useRef } from 'react'
import {
    FilePdfOutlined, DownloadOutlined, EyeOutlined, FileJpgOutlined,
    FileWordOutlined,
    FilePptOutlined,
    CloseCircleOutlined
} from "@ant-design/icons";

const Data = (props) => {
    const viewData = useRef()
    let picture = props.data?.doc_path;
    let ext = picture && picture.split(".").pop();
    const viewHandler = () => {
        viewData.current.style.display = "flex"
    }
    const viewCloseHnadler = () => {
        viewData.current.style.display = "none"
    }
    const viewNextTab = (resp) => {
        //href={} target="_blank"
    }
    return (
        <>

            <div className="generated_data_post_pre_select">
                <div className="generated_post_pre_date">
                    {(props.data && props.data.customer_details?.name.length <= 15)?
                           <p>CUSTOMER NAME : {props.data && props.data.customer_details.name}</p>:
                             <p>CUSTOMER NAME : {props.data && props.data.customer_details.name.slice(0, 15)}...</p>}
                </div>
                <div className="generated_post_pre_name">
                    <p>EQUIPMENT NO : {props.data && props.data.asset_details.equipment_no}</p>
                </div>
                <div className="generated_post_pre_name">
                    {(() => {
                        if (props.data?.asset_txns_details?.ticket_details?.ticket_ref_id === null) {
                            return <p>TICKET NO : NA </p>
                        }else if(props.data?.asset_txns_details?.ticket_details===null){
                            return <p>TICKET NO : NA </p>
                        }
                        else {
                            return <p>TICKET NO : {props.data?.asset_txns_details?.ticket_details?.ticket_ref_id}</p>
                        }
                    })()}
                </div>
                <div className="generate_post_pre_doc_icon" style={{ fontSize: "2rem" }}>
                    {(() => {
                        if (ext === "pdf") {
                            return <FilePdfOutlined />
                        }
                        else if (ext === "jpg") {
                            return <FileJpgOutlined />
                        }
                        else if (ext === "png") {
                            return <FilePptOutlined />
                        } else if (ext === "pptx") {
                            return <FilePdfOutlined />
                        }
                        else {
                            return <p style={{ fontSize: "1rem", lineHeight: "55px" }}>NA</p>
                        }
                    })()}

                </div>
                <div className="generated_post_pre_select_view">
                    <div className="generated_pre_post_check">
                        <a href={props.data && props.data?.doc_path} style={{ color: "#ffffff" }}>
                            <DownloadOutlined style={{ fontSize: "1rem", marginRight: "5px" }} />
                            <span>Download</span>
                        </a>
                    </div>
                    {(() => {
                        if (ext === "pdf") {
                            return <div className="generated_pre_post_view" onClick={() => { viewNextTab(props.data && props.data?.doc_path) }}>
                                <EyeOutlined style={{ fontSize: "1rem", marginRight: "7px" }} />
                                <span>view</span>
                            </div>
                        }
                        else {
                            return <div className="generated_pre_post_view" onClick={viewHandler}>
                                <EyeOutlined style={{ fontSize: "1rem", marginRight: "7px" }} />
                                <span>view</span>
                            </div>
                        }
                    })()}
                </div>

            </div>
            <div className="veiw_data" ref={viewData}>
                <div className="veiw_data_content">
                    <div className="view_close" onClick={viewCloseHnadler}>
                        <CloseCircleOutlined />
                    </div>
                    <div className="view_data_imga">
                        <img src={props.data && props.data?.doc_path} alt="documents details" />
                    </div>
                </div>
            </div>
        </>
    )
}
export default Data;